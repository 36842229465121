<template>
  <div v-if="loading">
    <sdCards headless>
      <div class="spin">
        <a-spin />
      </div>
    </sdCards>
  </div>
  <div v-else>
    <sdPageHeader>
      <template #title>
        <a-row :gutter="25">
          <a-col>
            <div class="setting-card-title">
          <sdHeading class="product-single-title" as="h1" style="font-size: 26px;color:#0261db; margin-bottom: 0 !important;">
           {{ orgdata.client_name }} - Bookkeeping Health Check Overview
          </sdHeading>
            </div>
          </a-col>
        </a-row>
      </template>
      <template v-slot:buttons>
        <div class="page-header-actions" style="margin-top: -35px !important; ">
          <a-button
            class="btn-signin"
            size="small"
            @click="exportToCSV()"
          >
            <span
              style="
                display: inline-flex !important;
                align-items: center !important;
              "
            >
              <sdFeatherIcons type="download" size="18" /> </span
            >
          </a-button>
          <div v-if="refresh_btn">
            <a-button
              id="disabled"
              class="ant-btn ant-btn-primary ant-btn-sm"
              size="small"
              disabled
              @click="refresh()"
            >
              <span
                style="
                  display: inline-flex !important;
                  align-items: center !important;
                "
              >
                <sdFeatherIcons type="loader" size="14" /> &nbsp;
                Get Counts</span
              >
            </a-button>
          </div>
          <div v-else>
            <a-button
              id="disabled"
              class="ant-btn ant-btn-primary ant-btn-sm"
              size="small"
              @click="refresh()"
            >
              <span
                style="
                  display: inline-flex !important;
                  align-items: center !important;
                "
              >
                <!-- <sdFeatherIcons type="loader" size="14" /> &nbsp; -->
                Get Counts</span
              >
            </a-button>
          </div>
          
        </div>
        
      </template>
    </sdPageHeader>
    <Main>
      <a-row :gutter="25">
        <a-col :xxl="4" :md="6" :sm="6" :xs="6">
          <sdCards title="Health Score" style="height: 95% !important">
            <div class="notification">
              <ActivityContents>
                <SentEmailWrapper>
                  <div
                    class="sent-emial-chart"
                    :style="{ textAlign: 'center' }"
                  >
                    <sdCards headless>
                      <a-progress
                        :width="150"
                        :strokeWidth="7"
                        :style="{
                          textAlign: 'center',
                          width: '100%',
                          height: '100%',
                        }"
                        type="circle"
                        :stroke-color="{
                          '100%': '#FF69A5',
                          '0%': '#5F63F2',
                        }"
                        :percent="orgdata.health_score"
                      />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                     <h4 style="margin-top: 10% !important;"><sdFeatherIcons type="calendar" size="14" style="font-size: 14px;color:#7fd9f0"/>
                      Ignoring issues before: <br/>{{ orgdata.syncdate1 }}
                      </h4> 
                    </sdCards></div></SentEmailWrapper
              ></ActivityContents></div
          ></sdCards>
        </a-col>
        <a-col :xxl="12" :md="18" :sm="18" :xs="18">
        <sdHeading class="product-single-title" as="h1" style="font-size: 20px;color:#0261db;">
           Basis of check</sdHeading>
        <a-row :gutter="25">
        
        <a-col :xxl="6" :md="8" :sm="8" :xs="12">
          <ProductCard
          class="list-view"
          :style="{ width: '100%', marginBottom: '20px' }"
          >
          <div class="product-list" style="padding: 10px;background-color:#ffffff;-webkit-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);">
          <a-row :gutter="24">
          <a-col :md="4" :xs="4"></a-col>
          <a-col :md="18" :xs="18">
          <div class="product-single-description">
          <sdHeading
          class="product-single-title"
          style="margin: 0px; font-size: 18px; font-weight: 600; color:#666666;"
          ><sdFeatherIcons type="calendar" size="18" style="color:#7fd9f0"/>
          Period Checked:
          </sdHeading>
          </div>
          </a-col>
          </a-row>
          <a-row :gutter="24">
          
          <a-col :md="22" :xs="22">
          <sdHeading
          class="product-single-title"
          style="left: -10px !important; margin: 0px 0px 0px 20px; font-size: 16px; font-weight: 800; color:#0261db;"
          >
          {{ orgdata.syncdate1 }}
          </sdHeading>
          </a-col>
          </a-row>
          </div>
          </ProductCard>
        </a-col>
        <a-col :xxl="6" :md="8" :sm="8" :xs="12">
          <ProductCard
          class="list-view"
          :style="{ width: '100%', marginBottom: '20px' }"
          >
          <div class="product-list" style="padding: 10px;background-color:#ffffff;-webkit-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);">
          <a-row :gutter="24">
          <a-col :md="4" :xs="4"></a-col>
          <a-col :md="18" :xs="18">
          <div class="product-single-description">
          <sdHeading
          class="product-single-title"
          style="margin: 0px; font-size: 18px; font-weight: 600; color:#666666;"
          ><sdFeatherIcons type="list" size="18" style="color:#7fd9f0"/>
          Items Checked:
          </sdHeading>
          </div>
          </a-col>
          </a-row>
          <a-row :gutter="24">
          <a-col :md="4" :xs="4"></a-col>
          <a-col :md="18" :xs="18">
          <sdHeading
          class="product-single-title"
          style="left: -10px !important; margin: 0px 0px 0px 20px; font-size: 18px; font-weight: 800; color:#0261db;"
          >
          {{orgdata.itemsChecked}}
          </sdHeading>
          </a-col>
          </a-row>
          </div>
          </ProductCard>
        </a-col>
        <a-col :xxl="6" :md="8" :sm="8" :xs="12">
          <ProductCard
          class="list-view"
          :style="{ width: '100%', marginBottom: '20px' }"
          >
          <div class="product-list" style="padding: 10px;background-color:#ffffff;-webkit-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);">
          <a-row :gutter="24">
          <a-col :md="4" :xs="4"></a-col>
          <a-col :md="18" :xs="18">
          <div class="product-single-description">
          <sdHeading
          class="product-single-title"
          style="margin: 0px; font-size: 18px; font-weight: 600; color:#666666;"
          ><sdFeatherIcons type="clipboard" size="18" style="color:#7fd9f0"/>
          Number of Checks:
          </sdHeading>
          </div>
          </a-col>
          </a-row>
          <a-row :gutter="24">
          <a-col :md="4" :xs="4"></a-col>
          <a-col :md="18" :xs="18">
          <sdHeading
          class="product-single-title"
          style="left: -10px !important; margin: 0px 0px 0px 20px; font-size: 18px; font-weight: 800; color:#0261db;"
          >
          45
          </sdHeading>
          </a-col>
          </a-row>
          </div>
          </ProductCard>
        </a-col>
        </a-row>
        <sdHeading class="product-single-title" as="h1" style="font-size: 20px;color:#0261db;">
           Summary of results</sdHeading>
        <a-row :gutter="25">
        <a-col :xxl="6" :md="8" :sm="8" :xs="12">
          <ProductCard
          class="list-view"
          :style="{ width: '100%', marginBottom: '20px' }"
          >
          <div class="product-list" style="padding: 10px;background-color:#ffffff;-webkit-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);">
          <a-row :gutter="24">
          <a-col :md="4" :xs="4"></a-col>
          <a-col :md="18" :xs="18">
          <div class="product-single-description">
          <sdHeading
          class="product-single-title"
          style="margin: 0px; font-size: 18px; font-weight: 600; color:#666666;"
          ><sdFeatherIcons type="alert-circle" size="18" style="color:#7fd9f0"/>
          Potential Issues:
          </sdHeading>
          </div>
          </a-col>
          </a-row>
          <a-row :gutter="24">
          <a-col :md="4" :xs="4"></a-col>
          <a-col :md="18" :xs="18">
          <sdHeading
          class="product-single-title"
          style="left: -10px !important; margin: 0px 0px 0px 20px; font-size: 18px; font-weight: 800; color:#0261db;"
          >
          {{ orgdata.totalIssues }}
          </sdHeading>
          </a-col>
          </a-row>
          </div>
          </ProductCard>
        </a-col>
        <a-col :xxl="6" :md="8" :sm="8" :xs="12">
          <ProductCard
          class="list-view"
          :style="{ width: '100%', marginBottom: '20px' }"
          >
          <div class="product-list" style="padding: 10px;background-color:#ffffff;-webkit-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);">
          <a-row :gutter="24">
          <a-col :md="2" :xs="2"></a-col>
          <a-col :md="22" :xs="22">
          <div class="product-single-description">
          <sdHeading
          class="product-single-title"
          style="margin: 0px; font-size: 18px; font-weight: 600; color:#666666;"
          ><sdFeatherIcons type="briefcase" size="18" style="color:#7fd9f0"/>
          Potential Financial Errors:
          </sdHeading>
          </div>
          </a-col>
          </a-row>
          <a-row :gutter="24">
          <a-col :md="4" :xs="4"></a-col>
          <a-col :md="18" :xs="18">
          <sdHeading
          class="product-single-title"
          style="left: -10px !important; margin: 0px 0px 0px 20px; font-size: 18px; font-weight: 800; color:#0261db;"
          >
          {{orgdata.potentialIssues}}
          </sdHeading>
          </a-col>
          </a-row>
          </div>
          </ProductCard>
        </a-col>
        <a-col :xxl="6" :md="8" :sm="8" :xs="12">
          <ProductCard
          class="list-view"
          :style="{ width: '100%', marginBottom: '20px' }"
          >
          <div class="product-list" style="padding: 10px;background-color:#ffffff;-webkit-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);">
          <a-row :gutter="24">
          <a-col :md="4" :xs="4"></a-col>
          <a-col :md="18" :xs="18">
          <div class="product-single-description">
          <sdHeading
          class="product-single-title"
          style="margin: 0px; font-size: 18px; font-weight: 600; color:#666666;"
          ><sdFeatherIcons type="heart" size="18" style="color:#7fd9f0"/>
          Health Indicator:
          </sdHeading>
          </div>
          </a-col>
          </a-row>
          <a-row :gutter="24">
          <a-col :md="4" :xs="4"></a-col>
          <a-col :md="18" :xs="18">
          <sdHeading
          class="product-single-title"
          style="left: -10px !important; margin: 0px 0px 0px 20px; font-size: 18px; font-weight: 800; color:#0261db;"
          >
          {{orgdata.health_score}}%
          </sdHeading>
          </a-col>
          </a-row>
          </div>
          </ProductCard>
        </a-col>
        </a-row>
        </a-col>
        <a-col :xxl="4" :md="6" :sm="6" :xs="12">
          <Step1 :data="count" />
        </a-col>
        <a-col :xxl="4" :md="6" :sm="6" :xs="12">
          <Step2 :data="count" @open-modal="openModal" />
        </a-col>
        <a-col :xxl="4" :md="6" :sm="6" :xs="12">
          <Step3 :data="count" />
        </a-col>
        <a-col :xxl="4" :md="6" :sm="6" :xs="12">
          <Step4 :data="count" />
        </a-col>
      </a-row>
      <ModalVat v-if="showModal"  @close-modal="showModal = false" />
    </Main>
  </div>
  
</template>

<script>
import { Main } from "../../styled";

import { computed, ref, defineComponent, onMounted } from "vue";
import { SentEmailWrapper, ActivityContents, ProductCard } from "./style";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import Step1 from "./Step1.vue";
import Step2 from "./Step2.vue";
import Step3 from "./Step3.vue";
import Step4 from "./Step4.vue";
import ModalVat from "/src/components/Modal1.vue";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { message, Notification } from 'ant-design-vue'; 

export default defineComponent({
  name: "overview",
  components: {
    Main,
    ProductCard,
    ActivityContents,
    Step1,
    Step2,
    Step3,
    Step4,
    ModalVat,
    SentEmailWrapper,
  },
  setup(props) {
    const selected = ref(props.id);
    let ids = selected;
    let orgdata = ref("");
    let count = ref("");
    let client_name = ref("");
    const { state, dispatch } = useStore();
    var selectrange = ref(null);
    var loading = ref(true);
    var refresh_btn = ref(false);
    let showModal= ref(false);
    onMounted(() => {

      ids.value = localStorage.getItem("org");
      getoverview(ids.value);
      //alert("Error:Your Organisation Disconnected. Please Reconnect! ");
      //Notification["error"]({
      //        message: "error!",
      //        description: "Server Disconnected. Please Reconnect.",
      //      });
    });

    const openModal = () => {
    showModal.value=true;
    }
    const getoverview = (ids,refresh = 0) => {
      
      loading.value = true;
            
      let user = JSON.parse(localStorage.getItem("access_token"));
      if (user) {
        dispatch("getoverview", {ids,refresh}).then(
          (response) => {
            //console.log(response.data);
            count.value = response.data;
            orgdata.value = response.data.orgdata;
            localStorage.setItem("syncdata", JSON.stringify(orgdata.value));
            refresh_btn.value = false;
          },
          (error) => {
            Notification["error"]({
              message: "error!",
              description: "Server Error.",
            });
            refresh_btn.value = false;
          }
        );
      }
      loading.value = false;
      // refresh_btn.value = false;
    };

    const DateSelect = (date, dateString) => {
      selectrange.value = dateString;
    };

    const xerosync = () => {
     //console.log(selectrange);
      var range = selectrange.value.toString().split(",");
      let startdate = new Date(range[0]);
      let enddate = new Date(range[1]);
      startdate = startdate.toLocaleDateString("en-CA");
      enddate = enddate.toLocaleDateString("en-CA");

      var rangeobj = [];
      rangeobj.push(startdate);
      rangeobj.push(enddate);

      dispatch("getsync", rangeobj).then(
        (response) => {
          ids.value = localStorage.getItem("org");
          getoverview(ids.value);
          //  router.push("/overview");
          location.reload()
        },
        (error) => {}
      );
    };

    const refresh = () => {
      ids.value = localStorage.getItem("org");
      refresh_btn.value = true;
      getoverview(ids.value,1);
      // alert('refresh');
    }

    const exportToCSV = () => {
      const samplejson2 = [
        { Name: "Unreconciled Bank Transactions" , Count:count.value.unreconciled_bank_trans},
        { Name: "Capitalisation without Invoice" , Count:count.value.capitalisation_without_invoice},
        { Name: "Wrong Values in BS" , Count:count.value.bswrongvalues_count},
        { Name: "Unused Chart of Accounts" , Count:count.value.unused_chart_accounts},
        { Name: "Repeating Sales Invoice" , Count:count.value.repeating_sales_invoice},
        { Name: "Repeating Purchase Invoice" , Count:count.value.repeating_purchase_invoice},
        { Name: "Inconsistent VAT Codes" , Count:count.value.inconsistent_vat},
        { Name: "Inconsistent Nominal Codes" , Count:count.value.inconsistent_nominal},
        { Name: "Inconsistent Nominal Codes with VAT Codes" , Count:count.value.inconsistent_Nominal_with_vat},
        { Name: "NO VAT/ZERO RATED /EXEMPT VAT" , Count:count.value.incorrect_vat},
        { Name: "Manual Journals with VAT" , Count:count.value.manual_journals_vat},
        { Name: "New Contact Name" , Count:count.value.incorrect_contact},
        { Name: "Unallocated Payments" , Count:count.value.unallocated_Payments},
        { Name: "Partial Paid Bills" , Count:count.value.partial_payments},
        { Name: "Partial Paid Invoice" , Count:count.value.receipts_allocation},
        { Name: "PI Invoices in Drafts" , Count:count.value.pi_invoices_in_drafts},
        { Name: "Suppliers with Debit Balances" , Count:count.value.suppliers_with_debit_balances},
        { Name: "SI > 90 Days" , Count:count.value.pi_90_days},
        { Name: "Invoices Matched for Later Days" , Count:count.value.wrong_mismatch},
        { Name: "Unallocated Receipts" , Count:count.value.unallocated_receipts},
        { Name: "SI in Drafts" , Count:count.value.si_in_drafts},
        { Name: "PI > 90 days" , Count:count.value.si_90_days},
        { Name: "Payments expensed out but invoices are outstanding" , Count:count.value.payment_expensed},
      ];

      const fileName =
        orgdata.value.client_name + "Overview" + orgdata.value.syncdate;
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";

      const ws = XLSX.utils.json_to_sheet(samplejson2);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);

      Notification["success"]({
        message: "Success!",
        description: "Downloaded.",
      });
    };

    return {
      ids,
      orgdata,
      client_name,
      count,
      xerosync,
      selectrange,
      DateSelect,
      loading,
      exportToCSV,
      refresh,
      refresh_btn,
      openModal,
      showModal,
    };
  },
});
</script>
<style scoped>
.listbody {
  padding: 5px !important;
}
</style>
 
