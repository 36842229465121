<template>
  <sdCards title="Step 1 - Bookkeeping Review" style="height: 95% !important">
    <div class="notification">
      <ActivityContents>
        <ul class="activity-list">
          <li class="activity-list__single">
          <a-menu
            v-model:openKeys="openKeys"
            v-model:selectedKeys="selectedKeys"
            :theme="darkMode ? 'dark' : 'light'"
            style="height: 100% !important;"
          >
          <a-sub-menu key="dext_queries" style="font-size: 16px;">
          <template v-slot:title
            ><span>Dext Queries Review</span></template
          >
          <a-menu-item key="dext_link" style="font-size: 16px;">
            <router-link to="dext_link">
              Link Dext Account
            </router-link>
          </a-menu-item>
          <a-menu-item key="dext_pi" style="font-size: 16px;">
            <router-link to="dext_pi">
              Dext - Purchase Invoices(0)
            </router-link>
          </a-menu-item>
          <a-menu-item key="dext_expense"  style="font-size: 16px;">
            <router-link to="dext_expense">
              Dext - Expense Report(0)
            </router-link>
          </a-menu-item>
          <a-menu-item key="dext_si" style="font-size: 16px;">
            <router-link to="dext_si">
              Dext - Sales Invoices(0)
            </router-link>
          </a-menu-item>
          </a-sub-menu>
          </a-menu>
          </li>
          <li class="activity-list__single">
          <a-menu
            v-model:openKeys="openKeys"
            v-model:selectedKeys="selectedKeys"
            :theme="darkMode ? 'dark' : 'light'"
            style="height: 100% !important;"
          >
          <a-sub-menu key="bank_queries" style="font-size: 16px;">
          <template v-slot:title
            ><span>Bank Queies Review</span></template
          >
          <a-menu-item key="xero_unreconciled" style="font-size: 16px;">
            <router-link to="xero_unreconciled">
              Unreconciled Bank Transactions({{ data['unreconciled_bank_trans'] }})
            </router-link>
          </a-menu-item>
          <a-menu-item key="/"  style="font-size: 16px;">
            <router-link to="/">
              Outstanding Receipts(0)
            </router-link>
          </a-menu-item>
          <a-menu-item key="/" style="font-size: 16px;">
            <router-link to="/">
              Outstanding Payments(0)
            </router-link>
          </a-menu-item>
          </a-sub-menu>
          </a-menu>
          </li>
          <li class="activity-list__single">
          <a-menu
            v-model:openKeys="openKeys"
            v-model:selectedKeys="selectedKeys"
            :theme="darkMode ? 'dark' : 'light'"
            style="height: 100% !important;"
          >
          <a-sub-menu key="ap_queries" style="font-size: 16px;">
          <template v-slot:title
            ><span>Aged Payables</span></template
          >
          <a-menu-item key="xero_PIdraft" style="font-size: 14px;">
            <router-link to="xero_PIdraft">
              PI Invoices in Drafts({{ data['pi_invoices_in_drafts'] }})
            </router-link>
          </a-menu-item>
          <a-menu-item key="repeat_pinvoice" style="font-size: 14px;">
            <router-link to="repeat_pinvoice">
              Repeating Purchase Invoice({{ data['repeating_purchase_invoice'] }})
            </router-link>
          </a-menu-item>
          <a-menu-item key="xero_dupinvoices" style="font-size: 14px;">
            <router-link to="xero_dupinvoices">
              Duplicate Invoices({{ data['duplicate_invoices'] }})
            </router-link>
          </a-menu-item>
          <a-menu-item key="xero_pay" style="font-size: 14px;">
            <router-link to="xero_pay">
              Unallocated Payments({{ data['unallocated_Payments'] }})
            </router-link>
          </a-menu-item>
          <a-menu-item key="partial_pay" style="font-size: 14px;">
            <router-link to="partial_pay">
              Partial Paid Bills({{ data['partial_payments'] }})
            </router-link>
          </a-menu-item>
          <a-menu-item key="xero_debit" style="font-size: 14px;">
            <router-link to="xero_debit">
              Suppliers with Debit Balances({{ data['suppliers_with_debit_balances']  }})
            </router-link>
          </a-menu-item>
          <a-menu-item key="xero_payment_expensed" style="font-size: 14px;">
            <router-link to="xero_payment_expensed">
              Payments expensed out but invoices are outstanding({{ data['payment_expensed'] }})
            </router-link>
          </a-menu-item>
          <a-menu-item key="supplier-invoice" style="font-size: 14px;">
            <router-link to="supplier-invoice">
              O/S Invoices with XX Suppliers(0)
            </router-link>
          </a-menu-item>
          <a-menu-item key="xero_dabtorinvoices" style="font-size: 14px;">
            <router-link to="xero_dabtorinvoices">
              PI > 90 days({{ data['si_90_days'] }})
            </router-link>
          </a-menu-item>
          <a-menu-item key="value-invoice" style="font-size: 14px;">
            <router-link to="value-invoice">
              PI/SI Invoices with XX Value(0)
            </router-link>
          </a-menu-item>
          </a-sub-menu>
          </a-menu>
          </li>
          <li class="activity-list__single">
          <a-menu
            v-model:openKeys="openKeys"
            v-model:selectedKeys="selectedKeys"
            :theme="darkMode ? 'dark' : 'light'"
            style="height: 100% !important;"
          >
          <a-sub-menu key="ar_queries" style="font-size: 16px;">
          <template v-slot:title
            ><span>Aged Receivables</span></template
          >
          <a-menu-item key="xero_SIdraft" style="font-size: 14px;">
            <router-link to="xero_SIdraft">
              SI in Drafts({{ data['si_in_drafts'] }})
            </router-link>
          </a-menu-item>
          <a-menu-item key="repeat_sinvoice" style="font-size: 14px;">
            <router-link to="repeat_sinvoice">
              Repeating Sales Invoice({{ data['repeating_sales_invoice'] }})
            </router-link>
          </a-menu-item>
          <a-menu-item key="xero_dupinvoices" style="font-size: 14px;">
            <router-link to="xero_dupinvoices">
              Duplicate Invoices({{ data['duplicate_invoices'] }})
            </router-link>
          </a-menu-item>
          <a-menu-item key="xero_receipt" style="font-size: 14px;">
            <router-link to="xero_receipt">
              Unallocated Receipts({{ data['unallocated_receipts'] }})
            </router-link>
          </a-menu-item>
          <a-menu-item key="receipts_allocation" style="font-size: 14px;">
            <router-link to="receipts_allocation">
              Partial Paid Invoice({{ data['receipts_allocation'] }})
            </router-link>
          </a-menu-item>
          <a-menu-item key="xero_credit" style="font-size: 14px;">
            <router-link to="xero_credit">
              Customers with Credit Balances({{ data['customers_with_credit_balances']  }})
            </router-link>
          </a-menu-item>
          <a-menu-item key="xero_invoicematch" style="font-size: 14px;">
            <router-link to="xero_invoicematch">
              Invoices Matched for Later Dates({{ data['wrong_mismatch'] }})
            </router-link>
          </a-menu-item>
          <a-menu-item key="xero_foreign" style="font-size: 14px;">
            <router-link to="xero_foreign">
             Foreign Currency Invoices({{ data['foreign_currencies'] }})
            </router-link>
          </a-menu-item>
          <a-menu-item key="xero_allocation" style="font-size: 14px;">
            <router-link to="xero_allocation">
              Allocated Invoices({{ data['invoice_allocation'] }})
            </router-link>
          </a-menu-item>
          <a-menu-item key="xero_mode" style="font-size: 14px;">
            <router-link to="xero_mode">
              Payment Mode of Prevoiusly Paid Invoices({{data['xero_mode']}})
            </router-link>
          </a-menu-item>
          <a-menu-item key="xero_supplierinvoices" style="font-size: 14px;">
            <router-link to="xero_supplierinvoices">
              SI > 90 Days({{data['pi_90_days']}})
            </router-link>
          </a-menu-item>
          </a-sub-menu>
          </a-menu>
          </li>
          <li class="activity-list__single">
          <a-menu
            v-model:openKeys="openKeys"
            v-model:selectedKeys="selectedKeys"
            :theme="darkMode ? 'dark' : 'light'"
            style="height: 100% !important;"
          >
          <a-sub-menu key="dat_queries" style="font-size: 16px;">
          <template v-slot:title
            ><span>DAT Review</span></template
          >
          <a-menu-item key="xero_nominal" style="font-size: 14px;">
            <router-link to="xero_nominal">
              Inconsistent Nominal Codes({{ data['inconsistent_nominal'] }})
            </router-link>
          </a-menu-item>
          <a-menu-item key="xero_vat" style="font-size: 14px;">
            <router-link to="xero_vat">
              Inconsistent VAT Codes({{ data['inconsistent_vat'] }})
            </router-link>
          </a-menu-item>
          <a-menu-item key="xero_nominalvat" style="font-size: 14px;">
            <router-link to="xero_nominalvat">
              Inconsistent Nominal Codes with VAT Codes({{ data['inconsistent_Nominal_with_vat'] }})
            </router-link>
          </a-menu-item>
          <a-menu-item key="xero_transnominal" style="font-size: 14px;">
            <router-link to="xero_transnominal">
              NO VAT/ZERO RATED /EXEMPT VAT entries for review({{ data['incorrect_vat'] }})
            </router-link>
          </a-menu-item>
          <a-menu-item key="xero_revenue" style="font-size: 14px;">
            <router-link to="xero_revenue">
              Revenue with Payable/Spent({{ data['xero_revenue'] }})
            </router-link>
          </a-menu-item>
          <a-menu-item key="xero_expense" style="font-size: 14px;">
            <router-link to="xero_expense">
              Expense with Receivable/Received({{ data['xero_expense']  }})
            </router-link>
          </a-menu-item>
          </a-sub-menu>
          </a-menu>
          </li>
          <li class="activity-list__single">
          <a-menu
            v-model:openKeys="openKeys"
            v-model:selectedKeys="selectedKeys"
            :theme="darkMode ? 'dark' : 'light'"
            style="height: 100% !important;"
          >
          <a-sub-menu key="pl_check" style="font-size: 16px;">
          <template v-slot:title
            ><span>P&L Sense Check</span></template
          >
          </a-sub-menu>
          </a-menu>
          </li>
          <li class="activity-list__single">
          <a-menu
            v-model:openKeys="openKeys"
            v-model:selectedKeys="selectedKeys"
            :theme="darkMode ? 'dark' : 'light'"
            style="height: 100% !important;"
          >
          <a-sub-menu key="bl_check" style="font-size: 16px;">
          <template v-slot:title
            ><span>BL Sense Check</span></template
          >
          </a-sub-menu>
          </a-menu>
          </li>
         <li class="activity-list__single">
          <a-menu
            v-model:openKeys="openKeys"
            v-model:selectedKeys="selectedKeys"
            :theme="darkMode ? 'dark' : 'light'"
            style="height: 100% !important;"
          >
          <a-sub-menu key="step1_query" style="font-size: 16px;">
          <template v-slot:title
            ><span>Prepare Query Sheet</span></template
          >
          </a-sub-menu>
          </a-menu>
          </li>
         <li class="activity-list__single">
          <a-menu
            v-model:openKeys="openKeys"
            v-model:selectedKeys="selectedKeys"
            :theme="darkMode ? 'dark' : 'light'"
            style="height: 100% !important;"
          >
          <a-sub-menu key="step1_query_send" style="font-size: 16px;">
          <template v-slot:title
            ><span>Send Query Sheet to Client</span></template
          >
          </a-sub-menu>
          </a-menu>
          </li>
          <li class="activity-list__single">
          <a-menu
            v-model:openKeys="openKeys"
            v-model:selectedKeys="selectedKeys"
            :theme="darkMode ? 'dark' : 'light'"
            style="height: 100% !important;"
          >
          <a-sub-menu key="step1_send_reminder" style="font-size: 16px;">
          <template v-slot:title
            ><span>Send Reminder to Client</span></template
          >
          </a-sub-menu>
          </a-menu>
          </li>
        </ul>
      </ActivityContents>
    </div>
  </sdCards>
</template>

<script>
import { computed, ref, defineComponent, onMounted } from "vue";
import {
  UserTableStyleWrapper,
  ActivityContents,
  ProductCard,
} from "../style";

export default defineComponent({
  name: "Step1",
  props: ["data"],
  watch: {
    data: function (newVal, oldVal) {
      // watch it
      // console.log(newVal);
      // console.log(oldVal);
    },
  },
  components: { ActivityContents },

  setup() {
  
    return {
    };
  },
  methods: {},
});
</script>
<style scoped>
.sub-menu {
  margin-left: 20px;
  list-style-type: none;
}
</style>