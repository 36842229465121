<template>
  <sdCards title="Step 3 - Month/Quarter End Review" size="default" style="height: 95% !important">
    <div class="notification">
      <ActivityContents>
        <ul class="activity-list">
          <li class="activity-list__single">
          <a-menu
            v-model:openKeys="openKeys"
            v-model:selectedKeys="selectedKeys"
            :theme="darkMode ? 'dark' : 'light'"
            style="height: 100% !important;"
          >
          <a-sub-menu key="balance_reconciliation" style="font-size: 16px;">
          <template v-slot:title
            ><span>Balance Reconciliation</span></template
          >
          </a-sub-menu>
          </a-menu>
          </li>
          <li class="activity-list__single">
          <a-menu
            v-model:openKeys="openKeys"
            v-model:selectedKeys="selectedKeys"
            :theme="darkMode ? 'dark' : 'light'"
            style="height: 100% !important;"
          >
          <a-sub-menu key="me_checklist" style="font-size: 16px;">
          <template v-slot:title
            ><span>Month End Checklist</span></template
          >
          </a-sub-menu>
          </a-menu>
          </li>
          <li class="activity-list__single">
          <a-menu
            v-model:openKeys="openKeys"
            v-model:selectedKeys="selectedKeys"
            :theme="darkMode ? 'dark' : 'light'"
            style="height: 100% !important;"
          >
          <a-sub-menu key="send_me_checklist" style="font-size: 16px;">
          <template v-slot:title
            ><span>Send ME/QE Checklist</span></template
          >
          </a-sub-menu>
          </a-menu>
          </li>
        </ul>
      </ActivityContents>
    </div>
  </sdCards>
</template> 
<script>
import { computed, ref, defineComponent, onMounted } from "vue";
import {
  UserTableStyleWrapper,
  ActivityContents,
  ProductCard,
} from "../style";

export default defineComponent({
  name: "Step3",
  props: ["data"],
  watch: {
    data: function (newVal, oldVal) {
      // watch it
      // console.log(newVal);
      // console.log(oldVal);
    },
  },
  components: { ActivityContents },

  setup() {
    return {};
  },
  methods: {},
});
</script>
