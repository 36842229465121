<template>
  <sdCards title="Step 4 - Management Accounts" size="default" style="height: 95% !important">
    <div class="notification">
      <ActivityContents>
        <ul class="activity-list">
        <li class="activity-list__single">
          <a-menu
            v-model:openKeys="openKeys"
            v-model:selectedKeys="selectedKeys"
            :theme="darkMode ? 'dark' : 'light'"
            style="height: 100% !important;"
          >
          <a-sub-menu key="mgmt_report" style="font-size: 16px;">
          <template v-slot:title
            ><span>Generate Management Report</span></template
          >
          </a-sub-menu>
          </a-menu>
          </li>
        </ul>
      </ActivityContents>
    </div>
  </sdCards>
</template> 
<script>
import { computed, ref, defineComponent, onMounted } from "vue";
import {
  UserTableStyleWrapper,
  ActivityContents,
  ProductCard,
} from "../style";

export default defineComponent({
  name: "Step4",
  props: ["data"],
  watch: {
    data: function (newVal, oldVal) {
      // watch it
      // console.log(newVal);
      // console.log(oldVal);
    },
  },
  components: { ActivityContents },

  setup() {
    return {};
  },
  methods: {},
});
</script>
