
<template>
  <div class="modal-overlay" @click="$emit('close-modal')">
    <div class="modal" @click.stop>
        <a-row :gutter="25">
          <a-col>
            <BasicFormWrapper>
            <div class="setting-card-title">
              <sdHeading as="h5" style="font-weight: 500">
              <h3>VAT Return Period</h3><hr />
            <a-form
              name="contact"
              :rules="rules"
              :model="formState"
              @submit="handleOk"
              :layout="formState.layout"
            >
            <a-row>
            <a-col>
              <a-form-item name="selectrange1" label="Start Date:">
                <a-date-picker
                  style="padding-left: 25px !important"
                  @change="DateSelect1"
                  :defaultValue="selectrange1"
                  placeholder="Input StartDate"
                />
              </a-form-item>
            </a-col>
            <a-col>
              <a-form-item name="selectrange2" label="End Date:">
                <a-date-picker
                  @change="DateSelect2"
                  :defaultValue="selectrange2"
                  placeholder="Input EndDate"
                />
              </a-form-item>
            </a-col>
            </a-row>
            <a-row>
            <a-col>
              <a-form-item name="selectrange3" label="VAT Registered Date:">
                <a-date-picker
                  @change="DateSelect3"
                  :defaultValue="selectrange3"
                  placeholder="Input VAT Reg. Date"
                />
              </a-form-item>
            </a-col>
            </a-row>
            <a-row>
            <a-col>
                <a-button 
                style="margin: 0 20% !important"
                htmlType="submit"
                size="default"
                type="primary"
                key="submit"
              >
                Submit
              </a-button>
              </a-col>
              </a-row>
            </a-form>
            </sdHeading>
            </div>
          </BasicFormWrapper>
          </a-col>
         
          </a-row>
    </div>
    <div class="close" @click="$emit('close-modal')">
      <sdFeatherIcons type="x-circle" size="25" style="color:#fff !important;" />
    </div>
  </div>

</template>
<script>
import { Main, TableWrapper } from "/src/view/styled";
import { computed, ref, defineComponent, onMounted ,reactive} from "vue";
import { useRoute, useRouter } from "vue-router";
import { message, Notification } from "ant-design-vue";
import { useStore } from "vuex";
import { UserTableStyleWrapper, Action, ProductCard } from "/src/view/dashboard/style";
import * as FontAwesomeIcon from "@fortawesome/free-solid-svg-icons";
  export default defineComponent({
  name: "ModalVat",
  components: {
    Main,
    TableWrapper,
    UserTableStyleWrapper,
    ProductCard,
    FontAwesomeIcon,
    Action,
  },
  setup() {
    let orgdata = ref("");
    let count = ref("");
    let client_name = ref("");
    const param = useRoute();
    let showModal= ref(false);
    const router = useRouter();
    const { state, dispatch } = useStore();
    var selectrange = ref(null);
    var selectrange1 = ref(null);
    var selectrange2 = ref(null);
    var selectrange3 = ref(null);
    var loading = ref(true);
    onMounted(() => {
   
    });
    const formState = reactive({
      date1: "",
      date2: "",
      layout: "vertical",
      
    });
    const handleOk = (values) => {
      xerosync();
    };
   const DateSelect1 = (date, dateString) => {
      selectrange1.value = dateString;
    };
    const DateSelect2 = (date, dateString) => {
      selectrange2.value = dateString;
    };
    const DateSelect3 = (date, dateString) => {
      selectrange3.value = dateString;
    };

    const xerosync = () => {
      let startdate =selectrange1.value.toString();
      let enddate =selectrange2.value.toString();
      let regdate =selectrange3.value.toString();
      var rangeobj = [];
      rangeobj.push(startdate);
      rangeobj.push(enddate);
      rangeobj.push(regdate);
      dispatch("vatSync", rangeobj).then(
        (response) => {
          Notification["success"]({
              message: "VAT Review Range has been Updated",
          });
          location.reload(true);
        },
        (error) => {
        Notification["error"]({
              message: "Error!",
              description: error.data.msg
            });

        });
    };

    return {
      orgdata,
      client_name,
      showModal,
      count,
      xerosync,
      handleOk,
      formState,
      selectrange,
      selectrange1,
      selectrange2,
      selectrange3,
      DateSelect1,
      DateSelect2,
      DateSelect3,
      loading,
    };
  },
});

</script>

<style scoped>

.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #000000da;
}

.modal {
  text-align: center;
  background-color: white;
  height: 350px;
  width: 500px;
  margin-top: 10%;
  padding: 40px 20px 0px 20px;
  border-radius: 20px;
}
.close {
  margin: 10% 0 0 8px;
  cursor: pointer;
}

.close-img {
  width: 25px;
}

.check {
  width: 150px;
}

h6 {
  font-weight: 500;
  font-size: 28px;
  margin: 20px 0;
}

p {
  font-size: 16px;
  margin: 20px 0;
}

button {
  background-color: #5F63F2;
  width: 150px;
  height: 40px;
  color: white;
  font-size: 14px;
  border-radius: 16px;
  margin-top: 50px;
}
</style>