<template>
  <sdPageHeader>
    <template #title>
      <div class="product-single-description">
        <sdHeading class="product-single-title" as="h1" style="margin: 0px">
          Staff entertainment/UK Entertainment - 20% VAT. 
        </sdHeading>
        <span style="margin: 0px; font-size: 16px; font-weight: 400">
         Last Sync:
          {{
            orgdata.lastsync == "not_synced" ? "Not Synced" : orgdata.lastsync
          }}
        </span>
      </div>
      <a-row :gutter="25">
        <a-col>
          <div class="setting-card-title">
            <sdHeading as="h5" style="font-weight: 500">
              Transactions Between&nbsp;
              <sdFeatherIcons type="calendar" size="16" />&nbsp;
              <span style="margin: 0px; font-size: 16px; font-weight: 400">
                {{ orgdata.syncdate2 }}
              </span>
              <a-range-picker
                v-if="orgdata.lastsync == 'not_synced'"
                style="padding-top: 10px !important"
                @change="DateSelect"
                :defaultValue="selectrange"
              />
              &nbsp;
              <a-button
                size="medium"
                type="primary"
                v-on:click="xerosync()"
                v-if="orgdata.lastsync == 'not_synced'"
              >
                <sdFeatherIcons type="refresh-cw" size="14" />
                Sync Now
              </a-button>
            </sdHeading>
          </div>
        </a-col></a-row
      >
    </template>
    <template v-slot:buttons>
      <ProjectSorting>
        <div class="project-sort-bar">
          <div class="project-sort-group">
            <div class="sort-group">
              <a-select
                @change="onSorting"
                defaultValue="Active"
                class="border"
              >
                <a-select-option value="Active">Active</a-select-option>
                <a-select-option value="Ignored">Dismissed</a-select-option>
              </a-select>
            </div>
          </div>
        </div>
      </ProjectSorting>
    </template>
  </sdPageHeader>
  <Main>
    <!--<Description text="Identify Suppliers/Customers contact name wise where multiple invoices are booked with multiple VAT codes.(Bank Transactions & Invoices).
    Disclaimer - All transactions will be shown positive values even if they are debit/ credits." />-->
    <sdCards headless v-if="loading">
      <div class="spin">
        <a-spin />
      </div>
    </sdCards>
    <sdCards headless v-else>
      <template v-if="selectedoption == 'Active'">
        <a-row :gutter="15" style="padding: 10px">
          <a-col :xs="24">
            <TopToolBox>
              <div class="product-single-description">
                <div
                  class="
                    product-single-title
                    page-header-actions
                    product-single-description
                  "
                  style="float: right"
                >
                  <a-button
                    class="btn-signin"
                    size="small"
                    type="default"
                    v-if="checkselected"
                    @click="bulkIgnore('B')"
                  >
                    <span
                      style="
                        display: inline-flex !important;
                        align-items: center !important;
                      "
                    >
                      <sdFeatherIcons type="x-circle" size="14" /> &nbsp;
                      Dismiss</span
                    >
                  </a-button>
                  <a-form
                      name="contact"
                      :rules="rules"
                      :model="addFormState"
                      :layout="addFormState.layout"
                      style="
                        margin-top: 20px;
                        margin-right: 10px;
                      "
                    >
                      <a-row  >
                        <a-col>
                          <a-form-item name="search_text">
                            <a-input
                              v-model:value="addFormState.search_text"
                              placeholder="Search Here...."
                              @change="handleSearch"
                            />
                          </a-form-item>
                        </a-col>
                      </a-row>
                    </a-form>
                  <a-button
                    class="btn-signin"
                    size="small"
                    @click="exportToCSV()"
                  >
                    <span
                      style="
                        display: inline-flex !important;
                        align-items: center !important;
                      "
                    >
                      <sdFeatherIcons type="download" size="14" /> &nbsp;
                      Export</span
                    >
                  </a-button>
                </div>
                <span style="margin: 0px; font-size: 16px; font-weight: 400">
                  <br />
                </span>
              </div>
            </TopToolBox>
          </a-col>
        </a-row>
        <a-row :gutter="15">
          <a-col :md="24">
            <TableWrapper class="table-responsive">
              <a-table
                class="ant-table-striped"
                :data-source="TableData"
                :columns="TableColumns"
                :row-class-name="
                  (_record, index) => (index % 2 === 1 ? 'table-striped' : null)
                "
                :rowSelection="rowSelection"
                :pagination="{
                  defaultPageSize: 10,
                  total: TableData.length,
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                }"
              />
            </TableWrapper>
          </a-col>
        </a-row>
      </template>
      <template v-else>
        <a-row :gutter="15" style="padding: 10px">
          <a-col :xs="24">
            <TopToolBox>
              <div class="product-single-description">
                <div
                  class="
                    product-single-title
                    page-header-actions
                    product-single-description
                  "
                  style="float: right"
                >
                  <a-button
                    class="btn-signin"
                    size="small"
                    type="default"
                    v-if="checkselected"
                    @click="bulkIgnore('A')"
                  >
                    <span
                      style="
                        display: inline-flex !important;
                        align-items: center !important;
                      "
                    >
                      <sdFeatherIcons type="check-circle" size="14" /> &nbsp;
                      Active</span
                    >
                  </a-button>
                  <a-form
                      name="contact"
                      :rules="rules"
                      :model="addFormState"
                      :layout="addFormState.layout"
                      style="
                        margin-top: 20px;
                        margin-right: 10px;
                      "
                    >
                      <a-row  >
                        <a-col>
                          <a-form-item name="search_text">
                            <a-input
                              v-model:value="addFormState.search_text"
                              placeholder="Search Here...."
                              @change="handleSearch"
                            />
                          </a-form-item>
                        </a-col>
                      </a-row>
                    </a-form>
                  <a-button
                    class="btn-signin"
                    size="small"
                    @click="exportToCSV()"
                  >
                    <span
                      style="
                        display: inline-flex !important;
                        align-items: center !important;
                      "
                    >
                      <sdFeatherIcons type="download" size="14" /> &nbsp;
                      Export</span
                    >
                  </a-button>
                </div>
                <span style="margin: 0px; font-size: 16px; font-weight: 400">
                  <br />
                </span>
              </div>
            </TopToolBox>
          </a-col>
        </a-row>
        <a-row :gutter="15">
          <a-col :md="24">
            <TableWrapper class="table-responsive">
              <a-table
                class="ant-table-striped"
                :dataSource="ignoreTableData"
                :rowSelection="rowSelection"
                :columns="TableColumns"
                :row-class-name="
                  (_record, index) => (index % 2 === 1 ? 'table-striped' : null)
                "
                :pagination="{
                  defaultPageSize: 10,
                  total: ignoreTableData.length,
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                }"
              />
            </TableWrapper>
          </a-col>
        </a-row>
      </template>
    </sdCards>
    <a-modal
        :type="localState.modalType"
        title="Rectify Transaction"
        :visible="localState.modalVisible"
        :footer="null"
        :onCancel="handleCancel"
        :width= "720"
      >
      <div class="project-modal">
        <a-form
          name="contact"
          :model="localState"
          @submit="handleOk"
          layout="horizontal"
        >
        <a-row :gutter="30" style="" >
          <sdHeading class="product-single-title" as="h3" style="width: 100% !important; border-bottom: 1px solid #447316 !important;color: #447316 !important; margin: 15px" >
         Rectify Details:
          </sdHeading>
            </a-row>
            <a-row :gutter="30">
            <a-col :md="12">
              <a-form-item name="company" label="Account Code:">
                <a-select
                v-model:value="localState.account_code"
                style="width: 100%"
                showSearch
                >
                <a-select-option
                  v-for="item in accountData"
                  v-bind:key="item.name"
                  :value="item.name"
                >
                  {{ item.name }}
                  </a-select-option>
                </a-select> 
                </a-form-item>
            </a-col>
            <a-col :md="12">
              <a-form-item name="company" label="VAT Rate:">
                <a-select
                v-model:value="localState.vat_code"
                style="width: 100%"
                showSearch
                >
                <a-select-option
                  v-for="item in vatData"
                  v-bind:key="item.name"
                  :value="item.name"
                >
                  {{ item.name }}
                  </a-select-option>
                </a-select> 
                </a-form-item>
            </a-col>
            </a-row>
            <a-row :gutter="30">
            <a-col :md="3">
            <a-form-item>
              <a-button 
                htmlType="submit"
                size="default"
                type="primary"
                key="submit"
              >
              Update
              </a-button>
              </a-form-item>
              </a-col>
              </a-row>
            </a-form>
      </div>
      </a-modal>
  </Main>
</template>

<script>
import {
  ContactPageheaderStyle,
  Action,
  UserTableStyleWrapper,
  TopToolBox,
  ProjectSorting,
} from "../../style";
import { defineComponent } from "vue";
import { Main, CardToolbox, TableWrapper,BasicFormWrapper } from "../../../../styled";
import { dispatch, useStore } from "vuex";
import {
  defineAsyncComponent,
  computed,
  reactive,
  ref,
  onMounted,
  watch,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import { message, Notification } from "ant-design-vue";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Description from "./../Description.vue";
export default defineComponent({
  name: "Staff_Entertainment",
  components: {
    Main,
    Description,
    CardToolbox,
    TopToolBox,
    BasicFormWrapper,
    UserTableStyleWrapper,
    TableWrapper,
    ProjectSorting,
  },
  setup() {
    var loading = ref(true);
    var checkselected = ref(false);
    var selectcount = ref(null);
    var selectedid = ref(null);
    var selectedoption = ref("Active");
    var accountData = ref();
    var vatData = ref();
    const { dispatch, state } = useStore();
    const orgdata = ref(JSON.parse(localStorage.getItem("syncdata")));

    onMounted(() => {
      getdata();
      loadData();
    });
    const addFormState = reactive({
      search_text: "",
      layout: "vertical",
    });
    const localState = reactive({
      modalVisible: false,
       modalType: "primary",
       account_code:"",
       vat_code:"",
       invoice_id:"",
       type:"",
       line_id:"",
    });
    const openModal = (id,account_name,vat_name,type,line_id) => {
      localState.modalVisible= true;
      localState.invoice_id = id;
      localState.account_code = account_name;
      localState.vat_code = vat_name;
      localState.type = type;
      localState.line_id= line_id;
    }
    const handleCancel = () => {
      localState.modalVisible= false;
    };
    const handleOk = () => {
      postData();
    };
    const postData= () => {
      let api_data = [];
      api_data['ids'] = localState.invoice_id;
      api_data['account_name'] = localState.account_code;
      api_data['vat_name']=localState.vat_code;
      api_data['type']=localState.type;
      api_data['line_id']=localState.line_id;
      dispatch("postLineitems",api_data).then(
        (response) => {
          if (response.status == 200) {
            Notification["success"]({
                    message: "Succsess!",
                    description: "Transaction Updated.",
                    });
                    //location.reload();
                    
           } else {
            //state.LeadsIndex.data = [];
           }
        },
        (error) => {
          Notification["error"]({
                message: "Error!",
                description: "Error Updating Information.",
            });
            //location.reload();
        }
      );
    };
    const loadData = () => {
        dispatch("codeFieldsData").then(
            (response) => {
                if (response.status == 200) {
                    //state.SearchData.data = response.data;
                    accountData.value = response.data[0];
                    vatData.value = response.data[1];
                    
                } else {
                    loading.value = false;
                    //onCancel();
                }
            },
            (error) => {
                loading.value = false;
                state.SearchData.data = [];
            } 
          
        );
    }
    const rules = {
      search_text: [
        {
          required: true,
          message: "Search Here....",
          trigger: "blur",
        },
      ],
      status: [
        {
          required: true,
          message: "Please Select a valid Option!",
          trigger: "blur",
        },
      ],
    };

    const handleSearch = (values) => {
      let substring = addFormState.search_text;
      state.BoxFour.data = JSON.parse(localStorage.getItem("search_data"));
      let xero_get_datas = [];
      (JSON.parse(localStorage.getItem("search_data"))).map((data,index) => {
        let search_match = false;
        search_match = (((data.contact_name).toLowerCase()).includes(substring.toLowerCase()));
        if(search_match == false){search_match = ((String(data.record_type)).includes(substring));
        }if(search_match == false){search_match = ((String(data.transactions)).includes(substring));
        }
        if(search_match == false){
          (state.BoxFour.data).splice(index, 1);
        }else{
          xero_get_datas.push(JSON.parse(localStorage.getItem("search_data"))[index])
        }
      });
      state.BoxFour.data = xero_get_datas;
    };
    const openurl = (id, type) => {
      let url = 'https://go.xero.com/AccountsPayable/Edit.aspx?InvoiceID=';
      switch (type) {
        case 'ACCPAY':
          url = 'https://go.xero.com/AccountsPayable/Edit.aspx?InvoiceID=';
          break;
        case 'ACCREC':
          url = 'https://go.xero.com/AccountsReceivable/Edit.aspx?InvoiceID=';
          break;
        case 'ACCRECCREDIT':
          url = 'https://go.xero.com/AccountsReceivable/ViewCreditNote.aspx?creditNoteID=';
          break;
        case 'ACCPAYCREDIT':
          url = 'https://go.xero.com/AccountsPayable/ViewCreditNote.aspx?creditNoteID=';
          break;
        case 'RECEIVE-OVERPAYMENT':
          url = 'https://go.xero.com/AccountsReceivable/ViewCreditNote.aspx?creditNoteID=';
          break;
        case 'SPEND-OVERPAYMENT':
          url = 'https://go.xero.com/AccountsPayable/ViewCreditNote.aspx?creditNoteID=';
          break; 
        case 'SPEND':
          url = 'https://go.xero.com/Bank/ViewTransaction.aspx?bankTransactionID=';
          break;  
        case 'RECEIVE':
          url = 'https://go.xero.com/Bank/ViewTransaction.aspx?bankTransactionID=';
          break;
        default:
          url = 'https://go.xero.com/AccountsPayable/Edit.aspx?InvoiceID=';
          break;
      }
      window.open(
          url + id,
          "_blank"
        ); 
    };

    const onSorting = (selectedItems) => {
      selectedoption.value = selectedItems;
      checkselected.value = false;
      selectcount.value = null;
      selectedid.value = null;
      state.BoxFour.data = [];

      if (selectedItems != "Active") {
        getignoredata();
      } else {
        getdata();
      }
    };

    const getdata = () => {
      loading.value = true;
      dispatch("StaffEntertainmentData", "none").then(
        (response) => {
          if (response.status == 200) {
            state.BoxFour.data = response.data;
            localStorage.setItem("search_data",JSON.stringify(response.data));
            loading.value = false;
          } else {
            state.BoxFour.data = [];
            loading.value = false;
            //onCancel();
          }
        },
        (error) => {
          state.BoxFour.data = [];
          loading.value = false;
        }
      );
    };

    const getignoredata = () => {
      loading.value = true;
      dispatch("StaffEntertainmentIgnore", "none").then(
        (response) => {
          if (response.status == 200) {
            state.BoxFour.data = response.data;
            localStorage.setItem("search_data",JSON.stringify(response.data));
            loading.value = false;
          } else {
            state.BoxFour.data = [];
            loading.value = false;
            //onCancel();
          }
        },
        (error) => {
          state.BoxFour.data = [];
          loading.value = false;
        }
      );
    };

    const onHandleignore = (id, status) => {
      var data = {};
      data.id = id;
      data.status = status;
      dispatch("ignoreStaffEntertainment", data).then(
        (response) => {
          if (response.status == 200) {
            Notification["success"]({
              message: "Success!",
              description: "Updated.",
            });

            if (selectedoption.value != "Active") {
              getignoredata();
            } else {
              getdata();
            }
          } else {
            Notification["error"]({
              message: "Error!",
              description: "Error .",
            });
            //onCancel();
          }
          checkselected.value = false;
          selectcount.value = null;
          selectedid.value = null;
        },
        (error) => {
          checkselected.value = false;
          selectcount.value = null;
          selectedid.value = null;
        }
      );
    };

    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        selectedRowKeys.value = selectedRowKeys;
        selectedRows.value = selectedRows;
        if (selectedRowKeys.value.length == 0) {
          checkselected.value = false;
        } else {
          checkselected.value = true;
          selectedid = selectedRowKeys.value;
          selectcount.value = selectedRowKeys.value.length + " Rows Selected";
        }
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === "Disabled User",
        name: record.name,
      }),
    };

    const DateSelect = (date, dateString) => {
      if (selectedoption.value == "Active") {
        loading.value = true;
        dispatch("StaffEntertainmentData", dateString).then(
          (response) => {
            if (response.status == 200) {
              state.BoxFour.data = response.data;
              loading.value = false;
            } else {
              state.BoxFour.data = [];
              loading.value = false;
              //onCancel();
            }
          },
          (error) => {
            state.BoxFour.data = [];
            loading.value = false;
          }
        );
      } else {
        loading.value = true;
        dispatch("StaffEntertainmentIgnore", dateString).then(
          (response) => {
            if (response.status == 200) {
              state.BoxFour.data = response.data;
              loading.value = false;
            } else {
              state.BoxFour.data = [];
              loading.value = false;
              //onCancel();
            }
          },
          (error) => {
            state.BoxFour.data = [];
            loading.value = false;
          }
        );
      }
    };

    const bulkIgnore = (option) => {
      var data = {};
      data.selectedid = selectedid;
      data.option = option;
      dispatch("StaffEntertainmentBulk", data).then(
        (response) => {
          if (response.status == 200) {
            Notification["success"]({
              message: "Success!",
              description: "Updated.",
            });
            if (selectedoption.value != "Active") {
              getignoredata();
            } else {
              getdata();
            }
          } else {
            Notification["error"]({
              message: "Error!",
              description: "Error .",
            });
            //onCancel();
          }
          checkselected.value = false;
          selectcount.value = null;
          selectedid.value = null;
        },
        (error) => {
          checkselected.value = false;
          selectcount.value = null;
          selectedid.value = null;
        }
      );
    };
    const TableColumns = [
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
        sorter: (a, b) => a.date.length - b.date.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Account",
        dataIndex: "account_name",
        key: "account_name",
        sorter: (a, b) => a.account_name.length - b.account_name.length,
        sortDirections: ["descend", "ascend"],
      },
      {
         title: "Customer Name ",
         dataIndex: "contact_name",
         key: "contact_name",
         sorter: (a, b) => a.contact_name.length - b.contact_name.length,
         sortDirections: ["descend", "ascend"],
      },
      {
         title: "Invoice No ",
         dataIndex: "invoice_no",
         key: "invoice_no",
         sorter: (a, b) => a.invoice_no.length - b.invoice_no.length,
         sortDirections: ["descend", "ascend"],
      },
      {
        title: "Vat Name",
        dataIndex: "vat_name",
        key: "vat_name",
        sorter: (a, b) => a.vat_name.length - b.vat_name.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Details",
        dataIndex: "description",
        key: "description",
        sorter: (a, b) => a.description.length - b.description.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Gross Amt",
        dataIndex: "gross_amount",
        key: "gross_amount",
        sorter: (a, b) => a.gross_amount.length - b.gross_amount.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "VAT Amt",
        dataIndex: "vat_amount",
        key: "vat_amount",
        sorter: (a, b) => a.vat_amount.length - b.vat_amount.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Net Amount ",
        dataIndex: "net_amount",
        key: "net_amount",
        sorter: (a, b) => a.net_amount.length - b.net_amount.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Attachment",
        dataIndex: "has_attachment",
        key: "has_attachment",
        sorter: (a, b) => a.has_attachment.length - b.has_attachment.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "link",
        dataIndex: "link",
        key: "link",
        sorter: (a, b) => a.link.length - b.link.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Actions",
        dataIndex: "action",
        key: "action",
        
      },
      
    ];

    var TableData = computed(() =>
      state.BoxFour.data
        /*.sort((a, b) => {
          return b.time - a.time;
        })*/
        .map((contact,index) => {
          const {
            id,
            contact_name,
            invoice_no,
            invoice_id,
            tax_amount,
            reference,
            currency_code,
            has_attachment,
            status,
            due_date,
            record_type,
            line_amount,
            unit_amount,
            quantity,
            tax_type,
            account_name,
            vat_name,
            line_item_id,
            description,
            type,
            } = contact;
          return {
            key: invoice_id,
            contact_name: contact_name,
            record_type:record_type,
            date: due_date,
              transaction_type: record_type,
              currency_code:currency_code,
              invoice_no: (
                <div class="table-actions">
                  <>
                    <p style="width: 100px;overflow:hidden;text-align: left;">
                      {" "}
                      {invoice_no}
                    </p>
                  </>
                </div>
              ),
              desc: (
                <div class="table-actions">
                  <>
                    <p style="width: 100px;overflow:hidden;text-align: left;">
                      {" "}
                      {reference}
                    </p>
                  </>
                </div>
              ),
              gross_amount: line_amount - tax_amount,
              vat_amount: tax_amount,
              net_amount: (unit_amount * quantity)+parseFloat(tax_amount),
              vat_code: tax_type,
              account_name:account_name,
              vat_name:vat_name,
              invoice_id:invoice_id,
              line_id:line_item_id,
              description:description,
              has_attachment:has_attachment,
              link: (
                <div class="table-actions">
                  <>
                    <a
                      onClick={() =>
                        openurl(invoice_id, type)
                      }
                      to="#"
                    >
                      <span style="display: inline-flex !important; align-items: center !important;">
                        <sdFeatherIcons
                          type="external-link"
                          size="22"
                          color="#1890ff"
                        />{" "}
                        &nbsp;View in Xero
                      </span>
                    </a>
                  </>
                </div>
              ),
              action: (
              <div>
                <>
                  <a-popconfirm
                    title="Are you sure ?"
                    ok-text="Yes"
                    cancel-text="No"
                    onConfirm={() => onHandleignore(invoice_id, "B")}
                  >
                    <a-button
                      size="default"
                      outlined="true"
                      raised="true"
                      type="info"
                      to="#"
                      id="dismiss_btn"
                    >
                      Dismiss
                    </a-button>
                  </a-popconfirm>
                </>
              </div>
            ),
             rectify: (
              <div>
                <>
                  <a-popconfirm
                    title="Are you sure ?"
                    ok-text="Yes"
                    cancel-text="No"
                    onConfirm={() => openModal(invoice_id,account_name,vat_name,type,line_item_id)}
                  >
                    <a-button
                      size="default"
                      outlined="true"
                      raised="true"
                      type="info"
                      to="#"
                      id="dismiss_btn"
                    >
                      Rectify
                    </a-button>
                  </a-popconfirm>
                </>
              </div>
            ),

          };
        })
    );

    var ignoreTableData = computed(() =>
      state.BoxFour.data
        /* .sort((a, b) => {
          return b.time - a.time;
        })*/
        .map((contact,index) => {
          const {
            id,
            name,
            invoice_no,
            invoice_id,
            tax_amount,
            reference,
            currency_code,
            has_attachment,
            status,
            due_date,
            record_type,
            contact_name,
            line_amount,
            unit_amount,
            quantity,
            tax_type,
            account_name,
            vat_name,
            line_item_id,
            description,
            type,
            } = contact;
          return {
            key: invoice_id,
            contact_name: contact_name,
            record_type:record_type,
            date: due_date,
              transaction_type: record_type,
              currency_code:currency_code,
              invoice_no: (
                <div class="table-actions">
                  <>
                    <p style="width: 100px;overflow:hidden;text-align: left;">
                      {" "}
                      {invoice_no}
                    </p>
                  </>
                </div>
              ),
              desc: (
                <div class="table-actions">
                  <>
                    <p style="width: 100px;overflow:hidden;text-align: left;">
                      {" "}
                      {reference}
                    </p>
                  </>
                </div>
              ),
              gross_amount: line_amount - tax_amount,
              vat_amount: tax_amount,
              net_amount: (unit_amount * quantity)+parseFloat(tax_amount),
              vat_code: tax_type,
              account_name:account_name,
              vat_name:vat_name,
              invoice_id:invoice_id,
              line_id:line_item_id,
              description:description,
              has_attachment:has_attachment,
              link: (
                <div class="table-actions">
                  <>
                    <a
                      onClick={() =>
                        openurl(invoice_id, type)
                      }
                      to="#"
                    >
                      <span style="display: inline-flex !important; align-items: center !important;">
                        <sdFeatherIcons
                          type="external-link"
                          size="22"
                          color="#1890ff"
                        />{" "}
                        &nbsp;View in Xero
                      </span>
                    </a>
                  </>
                </div>
              ),
              action: (
              <div>
                <>
                  <a-popconfirm
                    title="Are you sure ?"
                    ok-text="Yes"
                    cancel-text="No"
                    onConfirm={() => onHandleignore(invoice_id, "A")}
                  >
                    <a-button
                      size="default"
                      outlined="true"
                      raised="true"
                      type="info"
                      to="#"
                      id="dismiss_btn"
                    >
                      Active
                    </a-button>
                  </a-popconfirm>
                </>
              </div>
            ),
             rectify: (
              <div>
                <>
                  <a-popconfirm
                    title="Are you sure ?"
                    ok-text="Yes"
                    cancel-text="No"
                    onConfirm={() => openModal(invoice_id,account_name,vat_name,type,line_item_id)}
                  >
                    <a-button
                      size="default"
                      outlined="true"
                      raised="true"
                      type="info"
                      to="#"
                      id="dismiss_btn"
                    >
                      Rectify
                    </a-button>
                  </a-popconfirm>
                </>
              </div>
            ),

          };
        })
    );

    const exportToCSV = () => {
      const inputData = state.BoxFour.data.map((contact) => {
        var linedata = [];
        const { id, 
        contact_name,
        invoice_no,
        invoice_id,
        tax_amount,
        reference,
        currency_code,
        has_attachment,
        status,
        due_date,
        record_type,
        line_amount,
        unit_amount,
        quantity,
        tax_type,
        account_name,
        vat_name,
        line_item_id,
        description,
        type,
        acc_type,
        account_code,
         } = contact;
        return {
          key: id,
          contact_name: contact_name,
          record_type:record_type,
          due_date: due_date,
          invoice_no: invoice_no,
          type: type,
          desc: description,
          gross_amount: line_amount - tax_amount,
          vat_amount: tax_amount,
          net_amount: (unit_amount * quantity)+parseFloat(tax_amount),
          currency_code:currency_code,
          has_attachment:has_attachment,
          vat_code: tax_type,
          account_name: account_name,
          account_code: account_code,
          acc_type: acc_type,
          vat_name:vat_name,
          description:description,

        };
      });

      var linedata = [];
      var linedata1 = [];
      linedata1.push({
      Point : "Box 4 Checks- Staff Entertainment",
      Client: orgdata.value.client_name,
      "Synced Period": orgdata.value.syncdate2,
      
      });
      for (let i = 0; i < inputData.length; i++) {
        linedata.push({
          Date: inputData[i]["due_date"],
          "Contact Name": inputData[i]["contact_name"],
          "Account Name": inputData[i]["account_name"],
          "Reference": inputData[i]["invoice_no"],
          Details: inputData[i]["description"],
          "VAT Name": inputData[i]["vat_name"],
          "Gross Amount": inputData[i]["gross_amount"],
          "Vat Amount": inputData[i]["vat_amount"],
          "Net Amount": inputData[i]["net_amount"],
          "Attachment": inputData[i]["has_attachment"],
        });
      }

      const fileName =
        orgdata.value.client_name +
        " Box 4 Checks- Staff Entertainment" +
        orgdata.value.syncdate2;
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";

      const ws = XLSX.utils.json_to_sheet(linedata1);
      XLSX.utils.sheet_add_json(ws,linedata,{ skipHeader: false, origin: "A" + (linedata1.length + 3) });
      
      //ws.A1.v = "Client";
      //ws.A2.v = orgdata.value.client_name;
      //ws.B1.v = "Synced";
      //ws.B2.v = orgdata.value.syncdate;
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);

      Notification["success"]({
        message: "Success!",
        description: "Downloaded.",
      });
    };

    return {
      loading,
      TableColumns,
      TableData,
      rowSelection,
      selectcount,
      ignoreTableData,
      checkselected,
      selectedoption,
      bulkIgnore,
      onSorting,
      DateSelect,
      orgdata,
      exportToCSV,
      addFormState,
      handleSearch,
      localState,
      openModal,
      handleCancel,
      handleOk,
      accountData,
      vatData,
      postData,
    };
  },
});
</script>
 <style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #968f8f34;
}
#dismiss_btn {
  color: white;
  background-color: #5f63f2;
}
</style> 
