<template>
  <sdCards title="Step 2 - VAT Review" size="default" style="height: 95% !important">
    <div class="notification">
      <ActivityContents>
        <ul class="activity-list">
        <li class="activity-list__single">
          <a-menu
            v-model:openKeys="openKeys"
            v-model:selectedKeys="selectedKeys"
            :theme="darkMode ? 'dark' : 'light'"
            style="height: 100% !important;"
          >
          <a-sub-menu key="type_vat" style="font-size: 16px;">
          <template v-slot:title
            ><span>Type of VAT return</span></template
          >
          <a-menu-item key="type_vat" style="font-size: 14px;">
              Type : {{ data['orgdata']['SalesTaxBasis'] }}
          </a-menu-item>
          </a-sub-menu>
          
          </a-menu>
        </li>
        <li class="activity-list__single">
          <a-menu
            v-model:openKeys="openKeys"
            v-model:selectedKeys="selectedKeys"
            :theme="darkMode ? 'dark' : 'light'"
            style="height: 100% !important;"
          >
          <a-sub-menu key="freaquency_vat" style="font-size: 16px;">
          <template v-slot:title
            ><span>Frequency of VAT return</span></template
          >
          <a-menu-item key="frequency" style="font-size: 14px;">
              Frequency : {{ data['orgdata']['SalesTaxPeriod'] }}
          </a-menu-item>
          <a-menu-item key="vat_period" style="font-size: 14px;">
              <a-popconfirm
                title="Are you sure?"
                ok-text="Yes"
                cancel-text="No"
                @confirm="$emit('open-modal')"
              >
              <span>Change VAT Period</span>
              </a-popconfirm>
            </a-menu-item>
          </a-sub-menu>
          
          </a-menu>
        </li>
        <li class="activity-list__single">
          <a-menu
            v-model:openKeys="openKeys"
            v-model:selectedKeys="selectedKeys"
            :theme="darkMode ? 'dark' : 'light'"
            style="height: 100% !important;"
          >
          <a-sub-menu key="check_vat" style="font-size: 16px;">
          <template v-slot:title
            ><span>Check if 1st VAT return</span></template
          >
          <a-menu-item key="check_vat_filled" style="font-size: 14px;">
            <a target="_blank" :href="data.vat_link" style="font-size: 14px; color: #272B41;">Check Last Filled VAT Retuns </a>
          </a-menu-item>
          <a-menu-item key="click_if_new" style="font-size: 14px;">
            <a-checkbox @change="onChange" v-model:checked="formState.isCheck">Click if 1st VAT Return </a-checkbox>
          </a-menu-item>  
          <a-menu-item key="input_vat_purchase" style="font-size: 14px;" v-if="formState.isCheck">
            <router-link to="input_vat_purchase">
              Input vat on purchases - Upto 6 months prior to date of vat registration (0)
            </router-link>
          </a-menu-item>
          <a-menu-item key="input_vat_asset" style="font-size: 14px;" v-if="formState.isCheck">
            <router-link to="input_vat_asset">
             Input vat on assets - Upto 4 years prior to date of vat registration for capital goods/assets  purchased (0)
            </router-link>
          </a-menu-item>
          </a-sub-menu>
          </a-menu>
        </li>
        <li class="activity-list__single">
          <a-menu
            v-model:openKeys="openKeys"
            v-model:selectedKeys="selectedKeys"
            :theme="darkMode ? 'dark' : 'light'"
            style="height: 100% !important;"
          >
          <a-sub-menu key="box_1" style="font-size: 16px;">
          <template v-slot:title
            ><span>Box 1 Checks</span></template
          >
          <a-menu-item key="income_checks" style="font-size: 14px;">
            <router-link to="income_checks">
              Income – perform checks (in excel sheet) to ensure vat calculated at the correct rate on sales. ({{ data['income_check'] }})
            </router-link>
          </a-menu-item>
          <a-menu-item key="income_checkstt" style="font-size: 14px;">
            <router-link to="income_checkstt">
              Income Checks– Top 10 Transactions.
            </router-link>
          </a-menu-item>
          </a-sub-menu>
          </a-menu>
        </li>
        <li class="activity-list__single">
          <a-menu
            v-model:openKeys="openKeys"
            v-model:selectedKeys="selectedKeys"
            :theme="darkMode ? 'dark' : 'light'"
            style="height: 100% !important;"
          >
          <a-sub-menu key="box_4" style="font-size: 16px;">
          <template v-slot:title
            ><span>Box 4 Checks</span></template
          >
          <a-menu-item key="standard_rate_expense" style="font-size: 14px;">
            <router-link to="standard_rate_expense">
              For standard rates expenses({{ data['standard_expenses'] }})
            </router-link>
          </a-menu-item>
          <a-menu-item key="reversecharge" style="font-size: 14px;">
            <router-link to="reversecharge">
              Services purchased from outside UK & vat registration number is also outside UK- Reverse charge. ({{ data['reverse_charge'] }})
            </router-link>
          </a-menu-item>
          <a-menu-item key="staff_entertainment" style="font-size: 14px;">
            <router-link to="staff_entertainment">
              Staff entertainment/UK Entertainment - 20% VAT. ({{ data['staff_entertainment'] }})
            </router-link>
          </a-menu-item>
          <a-menu-item key="client_entertainment" style="font-size: 14px;">
            <router-link to="client_entertainment">
              Client entertainment - No vat (even if there is vat on invoice - it is disallowed as per HMRC law) ({{ data['client_entertainment'] }})
            </router-link>
          </a-menu-item>          
          </a-sub-menu>
          </a-menu>
        </li>
        <li class="activity-list__single">
          <a-menu
            v-model:openKeys="openKeys"
            v-model:selectedKeys="selectedKeys"
            :theme="darkMode ? 'dark' : 'light'"
            style="height: 100% !important;"
          >
          <a-sub-menu key="vat_specific" style="font-size: 16px;">
          <template v-slot:title
            ><span>VAT Specific Checks</span></template
          >
          <a-menu-item key="no_vat_transactions" style="font-size: 14px;">
            <router-link to="no_vat_transactions">
              NO VAT transaction.({{ data['no_vats'] }})
            </router-link>
          </a-menu-item>
          <a-menu-item key="zero_rated_transactions" style="font-size: 14px;">
            <router-link to="zero_rated_transactions">
              Zero rated transactions.({{ data['zero_rated'] }})
            </router-link>
          </a-menu-item>
          <a-menu-item key="vat_advertisement" style="font-size: 14px;">
            <router-link to="vat_advertisement">
              VAT on Advertisement expenses - Excluding Reversecharges VAT Code.({{ data['advertisement_vat'] }})
            </router-link>
          </a-menu-item>
          <a-menu-item key="vat_subscription" style="font-size: 14px;">
            <router-link to="vat_subscription">
              VAT on Subscription expenses - Excluding Reversecharges VAT Code.({{ data['subscription_vat'] }})
            </router-link>
          </a-menu-item>
          <a-menu-item key="vat_legal" style="font-size: 14px;">
            <router-link to="vat_legal">
              VAT on Legal expenses - Excluding NoVAT & Exempt VAT Code.({{ data['legal_vat'] }})
            </router-link>
          </a-menu-item>
          <a-menu-item key="vat_cis" style="font-size: 14px;">
            <router-link to="vat_cis">
              VAT on CIS invoices - CIS Labor, CIS Material, Sub-Contractors.({{ data['cis_vat'] }})
            </router-link>
          </a-menu-item>
          <a-menu-item key="vat_late_claims" style="font-size: 14px;">
            <router-link to="vat_late_claims">
              Late claims.(0)
            </router-link>
          </a-menu-item>
          <a-menu-item key="vat_lease" style="font-size: 14px;">
            <router-link to="vat_lease">
              VAT on Lease Rental - Check weather 50% VAT is Claimed.({{ data['lease_rental_vat'] }})
            </router-link>
          </a-menu-item>
          <a-menu-item key="vat_mot" style="font-size: 14px;">
            <router-link to="vat_mot">
              VAT on MOT - Excluding NoVAT Transactions.({{ data['mot_vat'] }})
            </router-link>
          </a-menu-item>          
          </a-sub-menu>
          </a-menu>
        </li>
        <li class="activity-list__single">
          <a-menu
            v-model:openKeys="openKeys"
            v-model:selectedKeys="selectedKeys"
            :theme="darkMode ? 'dark' : 'light'"
            style="height: 100% !important;"
          >
          <a-sub-menu key="trend_analysis" style="font-size: 16px;">
          <template v-slot:title
            ><span>Comparative Trend Analysis</span></template
          >
          <a-menu-item key="box1_updn" style="font-size: 14px;">
            <router-link to="box1_updn">
              Box 1 - Check which customer sales have gone up/down and by how many %.(0)
            </router-link>
          </a-menu-item>
          <a-menu-item key="box4_updn" style="font-size: 14px;">
            <router-link to="box4_updn">
              Box 4 - Check which expenses have gone up/down and by how many %.(0)
            </router-link>
          </a-menu-item>
          </a-sub-menu>
          </a-menu>
        </li>
        <li class="activity-list__single">
          <a-menu
            v-model:openKeys="openKeys"
            v-model:selectedKeys="selectedKeys"
            :theme="darkMode ? 'dark' : 'light'"
            style="height: 100% !important;"
          >
          <a-sub-menu key="vat_checklist" style="font-size: 16px;">
          <template v-slot:title
            ><span>Prepare VAT Checklist</span></template
          >
          </a-sub-menu>
          </a-menu>
        </li>
        <li class="activity-list__single">
          <a-menu
            v-model:openKeys="openKeys"
            v-model:selectedKeys="selectedKeys"
            :theme="darkMode ? 'dark' : 'light'"
            style="height: 100% !important;"
          >
          <a-sub-menu key="send_vat_checklist" style="font-size: 16px;">
          <template v-slot:title
            ><span>Send VAT Checklist</span></template
          >
          </a-sub-menu>
          </a-menu>
        </li>
        <li class="activity-list__single">
          <a-menu
            v-model:openKeys="openKeys"
            v-model:selectedKeys="selectedKeys"
            :theme="darkMode ? 'dark' : 'light'"
            style="height: 100% !important;"
          >
          <a-sub-menu key="send_payment_reminder" style="font-size: 16px;">
          <template v-slot:title
            ><span>Send VAT Payment Reminder</span></template
          >
          </a-sub-menu>
          </a-menu>
        </li>
        </ul>
      </ActivityContents>
    </div>
  </sdCards>
  
</template>

<script>
import { computed, ref, defineComponent, onMounted, reactive} from "vue";


import {
  UserTableStyleWrapper,
  ActivityContents,
  ProductCard,
} from "../style";

export default defineComponent({
  name: "Step2",
  props: ["data"],
  emits: ["open-modal"],
  watch: {
    data: function (newVal, oldVal) {
      // watch it
      // console.log(newVal);
      // console.log(oldVal);
    },
  },
  components: { ActivityContents },

  setup() {
    const formState = reactive({
          isCheck: false,
    });
    const onChange = (check) => {
      
    //alert(formState.isCheck);
      
    };
    return {
    onChange,
    formState,
    };
  },
  methods: {},
});
</script>
